import BadgerAccordion, { type AnimationAction } from "badger-accordion";
import MoveTo from "moveto";
import imagesLoaded from "imagesloaded";

let moveTo = new MoveTo();

export class CourseAccordion {
	childCourses: HTMLElement[];
	accordion: BadgerAccordion;

	constructor() {
		this.accordion = new BadgerAccordion(".js-badger-accordion", {
			openMultiplePanels: true,
		});

		let loader = imagesLoaded(".js-badger-accordion");
		loader.on("done", () => this.accordion.calculateAllPanelsHeight());

		if (location.hash.length > 1) {
			let activePanelIndex = this.accordion.panels.findIndex(e => e.getAttribute("data-hash") === location.hash.substring(1));
			if (activePanelIndex !== -1) {
				this.accordion.panels[activePanelIndex].addEventListener("transitionend", () => {
					moveTo.move(this.accordion.headers[activePanelIndex], { tolerance: 100 });
				});
				this.accordion.open(activePanelIndex);
			}
		}

		// setup mutation observer to change hash
		const observer = new MutationObserver((mutationsList, observer) => {
			for (let mutation of mutationsList.filter(m => m.type === "attributes" && m.target.nodeType === Node.ELEMENT_NODE)) {
				let target = mutation.target as HTMLElement;
				if (target.classList.contains("js-badger-accordion-panel")) {
					if (target.classList.contains(this.accordion.settings.activeClass)) {
						let hash = target.getAttribute("data-hash");
						history.replaceState(null, "", `#${hash}`);
						break; // break out of the loop because there could be other changes
					} else {
						history.replaceState(null, "", "#");
					}
				}
			}
		});
		observer.observe(this.accordion.container, { attributeFilter: ["class"], subtree: true,  });

		// setup toggle buttons (read more/close)
		for (let button of document.querySelectorAll(".badger-accordion__read-more")) {
			button.addEventListener("click", e => {
				let panel = button.closest(".js-badger-accordion-panel") as HTMLElement;
				if (panel == null) {
					return;
				}

				let index = this.accordion.panels.indexOf(panel);
				if (index === -1) {
					return;
				}

				let newState: AnimationAction = button.classList.contains("badger-accordion__read-more--close") ? "closed" : "open";
				this.accordion.togglePanel(newState, index);
			});
		}
	}
}
