import { Vue, Component, Prop } from "@wagich/vue-facing-decorator-metadata";

import { NewsletterSignup } from "./newsletter-signup";
import { DownloadOrder } from "./download-order";
import { BrochureOrder } from "./brochure-order";

import { render } from "./modal-trigger.html";

@Component({
	render,
	components: {
		NewsletterSignup,
		DownloadOrder,
		BrochureOrder
	}
})
export class ModalTrigger extends Vue {
	isOpen: boolean = false;

	@Prop() buttonText: string;
	@Prop() componentName: string;
	@Prop() componentProps: string;

	get bindableComponentProps(): any {
		if (this.componentProps != null && this.componentProps.length > 0) {
			try {
				return {
					...JSON.parse(this.componentProps),
					isModal: true,
				};
			} catch (e) {
			}
		}

		return {
			isModal: true,
		};
	}

	@Prop({ default: "accent" })
	color: string;

	open() {
		this.isOpen = true;
	}

	handleClose() {
		this.isOpen = false;
	}
}
