<v-list-item class="course-result__list-item" :prepend-avatar="imageUrl" @click="open">
	<v-list-item-title class="course-result__list-item-title">
		{{ value.name }}
		<template v-if="value.moduleName">
			<span> – {{ value.moduleName }}</span>
		</template>
		<template v-if="locationNames">
			<span class="course-result-list-item__locations grey--text text--darken-3"> – {{locationNames}}</span>
		</template>
	</v-list-item-title>
	<!--<v-list-item-subtitle class="truncate">{{ value.description }}</v-list-item-subtitle>-->
	<template v-slot:append>
		<favorite-toggle :courseId="value.courseId" :name="value.name" :moduleName="value.moduleName" :imageUrl="value.imageUrl" :url="value.url" />
	</template>
</v-list-item>
