import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";
import { format, formatISO, parseISO } from "date-fns";
import { de as deLocale } from "date-fns/locale";

import { render } from "./course-filter.html";
import { CourseFilterStore } from "./course-filter-storemodule";

interface Facets {
	[facetName: string]: FacetValues;
}
interface FacetValues {
	[facetValue: string]: number;
}

interface SelectValue {
	title: string;
	value: any | null;
}

@Component({
	render
})
export class CourseFilter extends Vue {
	private readonly store = new CourseFilterStore();

	@Prop({ required: true }) facets: Facets;

	get type() {
		return this.store.type;
	}
	set type(value) {
		this.store.setFilter({ type: value });
	}

	get category() {
		return this.store.category;
	}
	set category(value) {
		this.store.setFilter({ category: value });
	}

	get lecturer() {
		return this.store.lecturer;
	}
	set lecturer(value) {
		this.store.setFilter({ lecturer: value });
	}

	get location() {
		return this.store.location;
	}
	set location(value) {
		this.store.setFilter({ location: value });
	}

	get begin(): Date | null {
		if (this.store.begin == null) {
			return null;
		}
		return parseISO(this.store.begin);
	}
	set begin(value) {
		this.store.setFilter({ begin: value != null ? formatISO(value) : null });
	}

	get end() {
		if (this.store.end == null) {
			return null;
		}
		return parseISO(this.store.end);
	}
	set end(value) {
		this.store.setFilter({ end: value != null ? formatISO(value) : null });
	}

	get weekendsOnly() {
		return this.store.weekendsOnly;
	}
	set weekendsOnly(value) {
		this.store.setFilter({ weekendsOnly: value });
	}

	get noPrerequisites() {
		return this.store.noPrerequisites;
	}
	set noPrerequisites(value) {
		this.store.setFilter({ noPrerequisites: value });
	}

	get isFilterActive(): boolean {
		return this.store.isFilterActive;
	}

	resetFilter() {
		this.store.resetFilter();
	}

	extractFacetValues(values: FacetValues): SelectValue[] {
		if (values == null) {
			return [];
		}

		return Object.keys(values).map(value => { return { title: value, count: values[value], value }; });
	}
}
