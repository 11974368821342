import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";
import debounce from "lodash/debounce";
import groupBy from "lodash/groupBy";
import { format, fromUnixTime } from "date-fns";
import { de as deLocale } from "date-fns/locale";
import queryString from "query-string";

import { render } from "./course-list.html";
import type { SearchResultItem } from "../models/search-result-item";
import { idSafe } from "../utilities";
import { CourseFilter } from './course-filter';
import { CourseFilterStore, DisplayMode } from "./course-filter-storemodule";
import { CourseResultCard } from "./course-result-card";
import { CourseResultListItem } from './course-result-list-item';
import { CourseResultAgendaItem } from './course-result-agenda-item';
import { liteClient, type LiteClient } from "algoliasearch/lite";
import insightsClient from "search-insights";

enum State {
	Initial = "initial",
	Loading = "loading",
	ShowPartialResults = "showPartialResults",
	ShowAllResults = "showAllResults",
	NoResults = "noResults"
}

interface Facets {
	[facetName: string]: FacetValues;
}
interface FacetValues {
	[facetValue: string]: number;
}

interface CourseFilterHistoryState {
	pageInView?: number;
	scrollDepth?: number;
}

@Component({
render,
	components: {
		CourseResultCard,
		CourseResultListItem,
		CourseResultAgendaItem,
		CourseFilter
	},
	directives: {
		observe: {
			beforeMount: function (el: Element, binding: any) {
				let observer = binding.value as IntersectionObserver;
				observer.observe(el);
			}
		}
	}
})
export class CourseList extends Vue {
	private readonly store = new CourseFilterStore();
	private searchClient: LiteClient;

	observer = new IntersectionObserver(this.onIntersectionChanged, { threshold: [0, 0.01, 0.02, 0.98, 0.99, 1] });
	state: State = State.Initial;
	results: SearchResultItem[] = [];
	facets: Facets = {};
	queryId: string | null = null;

	lastLoadedPage: number = 0;
	inViewPage: number = 0;
	isFilterOverlayOpen: boolean = false;
	isInitialized: boolean = false;

	@Prop()
	apiKey: string;

	get resultsGroupedByMonth() {
		return groupBy(
			this.results.map((r, i) => { return { index: i, value: r }; }),
			x => x.value.beginTimestamp ? format(fromUnixTime(x.value.beginTimestamp), "MMMM yyyy", { locale: deLocale }) : "Beginn nach Stundenplan"
		);
	}

	get hitsPerPage(): number {
		return 12;
	}

	get displayMode() {
		return this.store.displayMode;
	}
	set displayMode(value) {
		if (this.store.displayMode === value) {
			return;
		}
		if (this.store.displayMode === DisplayMode.Agenda || value === DisplayMode.Agenda) {
			this.store.setDisplayMode(value);
			this.load();
		} else {
			this.store.setDisplayMode(value);
		}
	}

	get query() {
		return this.store.query;
	}
	set query(value) {
		this.store.setFilter({ query: value });
	}

	get isFilterActive(): boolean {
		return this.store.isFilterActive;
	}

	get isDateFilterActive(): boolean {
		return this.store.isDateFilterActive;
	}

	mounted() {
		this.searchClient = liteClient(import.meta.env.VITE_algoliaAppId!, this.apiKey);
		insightsClient("init", {
			appId: import.meta.env.VITE_algoliaAppId!,
			apiKey: this.apiKey,
			useCookie: true,
			cookieDuration: 86400000 // 24 hours
		});

		this.restoreState();
		this.previousObservedClientRectYs = {};
		window.addEventListener("scroll", this.onScrollDebounced);
	}

	beforeDestroy() {
		window.removeEventListener("scroll", this.onScrollDebounced);
	}

	readonly onScrollDebounced = debounce(this.onScroll, 250);
	onScroll() {
		let pushState: CourseFilterHistoryState = Object.assign({}, history.state, {
			scrollDepth: document.documentElement.scrollTop
		} as CourseFilterHistoryState);
		history.replaceState(pushState, "");
	}

	@Watch("store.query")
	onQueryChanged() {
		if (this.isInitialized) {
			this.store.resetFilter();
			this.loadDebounced();
		}
	}
	@Watch("store.filters")
	onFilterChanged() {
		// only do live-search on desktop (where there is no overlay)
		if (!this.isFilterOverlayOpen && this.isInitialized) {
			this.loadDebounced();
		}
	}
	@Watch("store.urlHash")
	onStoreHashChanged() {
		history.replaceState(history.state, "", "#!" + queryString.stringify({ s: this.store.urlHash }));
	}

	@Watch("inViewPage")
	onInViewPageChanged(page: number) {
		let pushState: CourseFilterHistoryState = Object.assign({}, history.state, { pageInView: this.inViewPage } as CourseFilterHistoryState);
		history.replaceState(pushState, "");
	}

	previousObservedClientRectYs: any;
	onIntersectionChanged(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
		console.log(entries);
		for (let entry of entries) {
			const index = parseInt(entry.target.getAttribute("data-index")!, 10);
			const page = Math.ceil((index + 1) / this.hitsPerPage) - 1;

			const isFirstOfPage = index % this.hitsPerPage === 0;
			const isLastOfPage = index % this.hitsPerPage === this.hitsPerPage - 1;

			const currentY = entry.boundingClientRect.top;
			const previousY = this.previousObservedClientRectYs[entry.target.id] || currentY;

			if (isLastOfPage && entry.intersectionRatio > 0.999) {
				// last-of-page element is fully visible
				if (this.inViewPage <= page) {
					this.inViewPage = page + 1;
				}
				if (page >= this.lastLoadedPage) {
					this.loadMore();
				}
			}
			else if (isFirstOfPage && currentY > previousY && !entry.isIntersecting) {
				// scrolling up, when first-of-page element is fully offscreen
				this.inViewPage = page - 1;
			}

			this.previousObservedClientRectYs[entry.target.id] = currentY;
		}
	}

	async restoreState() {
		if (location.hash.startsWith("#!s=")) {
			let data = queryString.parse(location.hash.substring(2)) as { s: string };
			this.store.setFromUrlHash(data.s);
		}

		let historyState = history.state as CourseFilterHistoryState | null;
		this.lastLoadedPage = historyState?.pageInView ?? 0;
		this.state = State.Loading;

		let data = await this.store.sendQuery({
			page: 0,
			hitsPerPage: (this.lastLoadedPage + 1) * this.hitsPerPage, // pages are 0-based!
			includeFacets: true,
			client: this.searchClient
		});
		this.facets = data.facets!;
		this.results = data.hits;
		this.queryId = data.queryID!;

		if (this.results.length === 0) {
			this.state = State.NoResults;
		}
		else if (data.nbPages === 1) {
			this.state = State.ShowAllResults;
		} else {
			this.state = State.ShowPartialResults;
		}

		this.isInitialized = true;
		this.$nextTick(() => {
			if (historyState?.scrollDepth != null) {
				document.documentElement.scrollTo({ top: historyState.scrollDepth });
			}
		});
	}

	readonly loadDebounced = debounce(this.load, 300);
	async load() {
		this.state = State.Loading;

		this.lastLoadedPage = 0;
		let data = await this.store.sendQuery({
			page: 0,
			hitsPerPage: this.hitsPerPage,
			includeFacets: true,
			client: this.searchClient
		});
		this.facets = data.facets!;
		this.results = data.hits;
		this.queryId = data.queryID!;
		window.scrollTo({ top: 0, behavior: "smooth" });

		if (this.results.length === 0) {
			this.state = State.NoResults;
		}
		else if (data.nbPages === 1) {
			this.state = State.ShowAllResults;
		} else {
			this.state = State.ShowPartialResults;
		}
	}

	async loadMore() {
		this.state = State.Loading;

		let data = await this.store.sendQuery({
			page: this.lastLoadedPage + 1,
			hitsPerPage: this.hitsPerPage,
			includeFacets: false,
			client: this.searchClient
		});
		this.results.push(...data.hits);
		this.lastLoadedPage++;
		this.queryId = data.queryID!;

		if (data.nbPages === 0 || data.nbPages <= this.lastLoadedPage - 1) {
			this.state = State.ShowAllResults;
		} else {
			this.state = State.ShowPartialResults;
		}
	}

	resetFilter() {
		this.store.resetFilter();
		window.scrollTo({top: 0, behavior: "smooth" });
	}

	onResultClicked(objectId: string, index: number) {
		let params = {
			eventName: "Course Clicked",
			index: this.store.indexName,
			queryID: this.queryId!,
			objectIDs: [objectId],
			positions: [index + 1],
		};
		insightsClient("clickedObjectIDsAfterSearch", params);
	}
}
