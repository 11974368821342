import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";

import { render } from "./favorites-tool.html";
import { FavoriteStore, type Favorite } from './favorite-storemodule';

@Component({
	render
})
export class FavoritesTool extends Vue {
	private store = new FavoriteStore();

	isOpen: boolean = false;

	get count(): number {
		return this.store.count;
	}

	get favorites(): Favorite[] {
		return this.store.favorites;
	}

	remove(favorite: Favorite) {
		this.store.remove(favorite.courseId);
	}

	async clear() {
		const clearConfirmed = await this.$dialog.confirm({
			level: "",
			icon: this.$icons.mdiAlert,
			title: "Merkliste löschen",
			text: "Willst du die Merkliste wirklich löschen?",
			confirmationText: "Merkliste löschen",
			confirmationButtonOptions: {
				variant: "flat",
				color: "accent",
			},
			cancelText: "Nein",
			persistent: true
		});
		if (clearConfirmed) {
			this.store.clear();
		}
	}
}
