import { Vue, Component, Prop } from "@wagich/vue-facing-decorator-metadata";
import urljoin from "url-join";

import { render } from "./course-navigation.html";

export const OverviewName = "uebersicht";
export const CoursesName = "ausbildungsinhalte";

export interface Page {
	name: string;
	title: string;
	url: string;
}

@Component({
	render
})
export class CourseNavigation extends Vue {
	@Prop({ required: true })
	baseUrl: string;
	@Prop({ default: false })
	showCourses: boolean;
	@Prop({ default: () => [] })
	pages: Page[];
	@Prop({ default: OverviewName })
	activeItemName: string;

	openPanel: number | null = null;

	activeItemModel: any = null;

	get activeItem(): Page | null {
		return this.items.find(i => i.name === this.activeItemName) || null;
	}

	get items(): Page[] {
		var items: Page[] = [{
			name: OverviewName,
			title: "Übersicht",
			url: this.baseUrl,
		}];
		if (this.showCourses) {
			items.push({
				name: CoursesName,
				title: "Ausbildungsinhalte",
				url: urljoin(this.baseUrl, CoursesName),
			});
		}
		items.push(...this.pages);
		return items;
	}
}
