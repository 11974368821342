<v-card class="course-result-agenda zoom-wrapper" @click="open">
	<v-card-text>
		<v-row dense>
			<v-col cols="auto">
				<v-avatar class="mr-2" :class="{'mt-2': value.moduleName != null && value.moduleName.length }">
					<v-img :src="imageUrl" />
				</v-avatar>
			</v-col>
			<v-col>
				<div class="course-result__date" v-if="hasDate"><strong>{{ date }}</strong>, {{ locationName }}</div>
				<h4 class="course-result__title">{{ value.name }}</h4>
				<div class="course-result__subtitle">{{ value.moduleName }}</div>
			</v-col>
			<favorite-toggle class="course-result-agenda__favorite-toggle" :courseId="value.courseId" :name="value.name" :moduleName="value.moduleName" :imageUrl="value.imageUrl" :url="value.url" />
		</v-row>
		<template v-if="!hideDescription">
			<!--<div class="course-result__description" v-html="descriptionSnippetHtml" />-->
		</template>
	</v-card-text>
</v-card>
