<v-card class="course-result-card white zoom-wrapper" @click="open">
	<div class="o-hidden">
		<v-img cover :src="imageUrl" :aspect-ratio="imageAspectRatio" class="zoom-subject" />
	</div>
	<v-card-text class="pb-0">
		<h3 class="course-result__title" :class="{ 'course-result-card__title--long': hasLongName }">{{ value.name }}</h3>
		<p class="course-result__subtitle" v-if="value.moduleName">{{ value.moduleName }}</p>
		<!--<p class="course-result__description" v-html="descriptionSnippetHtml"></p>-->
	</v-card-text>
	<v-card-actions class="pt-0">
		<v-row no-gutters align="center">
			<v-col class="pl-3 py-3">
				<!--<div class="course-result__locations grey--text text--darken-2">{{locationNames}}</div>-->
			</v-col>
			<v-col cols="auto">
				<favorite-toggle :courseId="value.courseId" :name="value.name" :moduleName="value.moduleName" :imageUrl="value.imageUrl" :url="value.url" />
			</v-col>
		</v-row>
	</v-card-actions>
</v-card>
