import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";

import { render } from "./favorite-toggle.html";
import { FavoriteStore } from './favorite-storemodule';

@Component({
	render
})
export class FavoriteToggle extends Vue {
	private store = new FavoriteStore();

	@Prop()
	courseId: string;
	@Prop()
	name: string;
	@Prop()
	moduleName: string;
	@Prop()
	imageUrl: string;
	@Prop()
	url: string;

	@Prop({ default: false })
	large: boolean;

	get isFavorited(): boolean {
		return this.store.favorites.find(f => f.courseId === this.courseId) != null;
	}

	toggle() {
		if (this.isFavorited) {
			this.store.remove(this.courseId);
		} else {
			this.store.add({
				courseId: this.courseId,
				name: this.name,
				moduleName: this.moduleName,
				imageUrl: this.imageUrl,
				url: this.url
			});
		}
	}
}
