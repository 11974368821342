<div>
	<v-btn :color="isFavorited ? '' : 'accent'" @click.prevent.stop="toggle" v-if="large">
		<template v-if="isFavorited">
			<v-icon class="mr-1" color="accent" :icon="$icons.mdiHeart"/>
			Von der Merkliste entfernen
		</template>
		<template v-else>
			<v-icon class="mr-1" :icon="$icons.mdiHeartOutline"/>
			Merken
		</template>
	</v-btn>
	<v-tooltip bottom v-else>
		<template v-slot:activator="{ props }">
			<v-btn icon size="small" variant="text" @click.prevent.stop="toggle" v-bind="props">
				<v-icon size="x-large" color="accent" :icon="$icons.mdiHeart" v-if="isFavorited"/>
				<v-icon size="x-large" color="primary" :icon="$icons.mdiHeartOutline" v-else/>
			</v-btn>
		</template>
		<span v-if="isFavorited">Von der Merkliste entfernen</span>
		<span v-else>Merken</span>
	</v-tooltip>
</div>
