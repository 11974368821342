import { Vue, Component, Prop } from "@wagich/vue-facing-decorator-metadata";
import ouibounce from "ouibounce";
import Cookies from "js-cookie";

import { render } from "./newsletter-overlay.html";
import { NewsletterSignup, NoNewsletterOverlayCookieName } from './newsletter-signup';

const OverlayViewedCookieName = "wasNewsletterOverlayViewed";

@Component({
	render
})
export class NewsletterOverlay extends NewsletterSignup {
	isActive: boolean = false;

	mounted() {
		if (Cookies.get(NoNewsletterOverlayCookieName) == "true") {
			return;
		}

		ouibounce(false, {
			cookieName: OverlayViewedCookieName,
			sitewide: true,
			sensitivity: 40,
			timer: 3,
			cookieExpire: 90,
			callback: () => this.show()
		});

		if (Cookies.get(OverlayViewedCookieName) != "true") {
			let handle = window.setTimeout(() => {
				Cookies.set(OverlayViewedCookieName, "true", { expires: 90 });
				this.show();
			}, 13000);
			document.addEventListener("keydown", () => window.clearTimeout(handle), { once: true });
		}
	}

	show() {
		this.isActive = true;
	}

	hide() {
		this.isActive = false;
	}
}
