<v-card class="contact-form bg-grey-lighten-4 pa-2">
	<v-card-title>
		<v-row>
			<v-col>
				<h3>Kontakt</h3>
			</v-col>
			<v-col cols="auto">
				<v-btn :icon="$icons.mdiClose" variant="text" @click="cancel"></v-btn>
			</v-col>
		</v-row>
	</v-card-title>
	<v-card-text>
		<v-container class="pa-0 clearfix mb-4">
			<v-row>
				<v-col cols="6">
					<v-text-field hide-details="auto" label="Vorname" placeholder="Maxine" autocomplete="given-name" :error-messages="validations.getErrors('firstname')" @update:modelValue="validate('firstname')" v-model="data.firstname" />
				</v-col>
				<v-col cols="6">
					<v-text-field hide-details="auto" label="Nachname" placeholder="Muster" autocomplete="family-name" :error-messages="validations.getErrors('lastname')" @update:modelValue="validate('lastname')" v-model="data.lastname" />
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<v-radio-group hide-details="auto" class="mt-0" inline :error-messages="validations.getErrors('gender')" @update:modelValue="validate('gender')" v-model="data.gender">
						<v-radio :label="femaleLabel" value="female" />
						<v-radio :label="maleLabel" value="male" />
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="6">
					<v-text-field hide-details="auto" label="Telefon" placeholder="012 345 67 89" autocomplete="tel" :error-messages="validations.getErrors('phone')" @update:modelValue="validate('phone')" v-model="data.phone" />
				</v-col>

				<v-col cols="6">
					<v-text-field hide-details="auto" label="E-Mail" placeholder="maxinemuster@example.com" autocomplete="email" :error-messages="validations.getErrors('email')" @update:modelValue="validate('email')" v-model="data.email" />
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-textarea hide-details="auto" label="Nachricht" :error-messages="validations.getErrors('message')" @update:modelValue="validate('message')" v-model="data.message" />
				</v-col>
			</v-row>
		</v-container>
	</v-card-text>
	<v-card-actions>
		<v-btn color="accent" variant="elevated" class="mr-2" :loading="state === 'loading'" @click="send">Absenden</v-btn>
	</v-card-actions>
</v-card>
