import { Vue, Component, Prop, Watch, Inject, Provide } from "@wagich/vue-facing-decorator-metadata";
import { create, enforce, omitWhen, only, test, warn, type SuiteResult } from "vest";
import "vest/enforce/email";

import { State, Gender } from "../utilities";
import { render } from "./brochure-order.html";

interface BrochureOrderRequest {
	firstname: string,
	lastname: string,
	address: string,
	postalCode: string,
	locality: string,
	email: string,
	gender: Gender | null,
	signupForNewsletter: boolean,
	brochures: string[],
}

const emptyData: BrochureOrderRequest = {
	firstname: "",
	lastname: "",
	address: "",
	postalCode: "",
	locality: "",
	email: "",
	gender: null,
	signupForNewsletter: false,
	brochures: [],
};

const validator = create((data: BrochureOrderRequest, fieldName: string | undefined = undefined) => {
	only(fieldName);

	test("gender", "Bitte wähle eine Anrede aus", () => {
		enforce(data.gender).inside([Gender.Male, Gender.Female]);
	});

	test("firstname", "Bitte gib deinen Vornamen ein", () => {
		enforce(data.firstname).isNotEmpty();
	});

	test("lastname", "Bitte gib deinen Nachnamen ein", () => {
		enforce(data.lastname).isNotEmpty();
	});

	test("email", () => {
		enforce(data.email)
			.message("Bitte gib deine E-Mail-Adresse ein")
			.isNotEmpty()
			.message("Bitte gib eine gültige E-Mail-Adresse ein")
			.isEmail();
	});

	test("address", "Bitte gib deine Adresse ein", () => {
		enforce(data.address).isNotEmpty();
	});

	test("postalCode", "Bitte gib deine Postleitzahl ein", () => {
		enforce(data.postalCode).isNotEmpty();
	});

	test("locality", "Bitte gib deinen Wohnort ein", () => {
		enforce(data.locality).isNotEmpty();
	});

	test("selectedBrochures", "Bitte wähle mindestens eine Broschüre aus", () => {
		enforce(data.brochures).isArray().isNotEmpty();
	});
});

@Component({
	render,
})
export class BrochureOrder extends Vue {
	validations: SuiteResult<string, string> = validator.get();
	state: State = State.Initial;

	@Prop({ required: false, default: false }) isModal: boolean;
	@Prop({ required: false, default: false }) flat: boolean;
	@Prop({ required: true }) dataSourceSelector: string;
	@Prop() contentSelector: string | null;

	contentHtml: string | null = null;
	brochures: string[] = [];
	data: BrochureOrderRequest = Object.assign({}, emptyData);

	get sendLabel(): string {
		return this.brochures && this.brochures.length === 1 ? "Broschüre bestellen" : "Broschüren bestellen";
	}

	get maleLabel(): string {
		return this.data.firstname ? `Lieber ${this.data.firstname}` : `Lieber Max`;
	}
	get femaleLabel(): string {
		return this.data.firstname ? `Liebe ${this.data.firstname}` : `Liebe Maxine`;
	}

	mounted() {
		let dataSourceElement = document.querySelector(this.dataSourceSelector);
		if (dataSourceElement != null && dataSourceElement.textContent != null) {
			this.brochures = JSON.parse(dataSourceElement.textContent);
		}

		if (this.contentSelector != null) {
			let contentSourceElement = document.querySelector(this.contentSelector);
			if (contentSourceElement != null && contentSourceElement.textContent != null) {
				this.contentHtml = contentSourceElement.innerHTML;
			}
		}

		this.preselectSingleBrochure();
	}

	async validate(fieldName?: string) {
		await this.$nextTick();
		this.validations = validator(this.data, fieldName);
	}

	async send() {
		await this.validate();
		if (!this.validations.isValid()) {
			return;
		}

		this.state = State.Loading;

		try {
			let responseMessage = await fetch("/api/brochure-order", {
				body: JSON.stringify(this.data),
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			});

			if (responseMessage.status < 300) {
				this.state = State.Success;

				try {
					gtag("event", "generate_lead", { event_category: "Bestellformular Broschüren" });
				} catch (ex) { }
				try {
					_paq.push(["trackEvent", "Formulare", "Broschüren bestellt"]);
				} catch { }

				this.data = Object.assign({}, emptyData);
				this.preselectSingleBrochure();
			} else {
				this.state = State.Error;
			}
		} catch (ex) {
			this.state = State.Error;
		}
	}

	private preselectSingleBrochure() {
		if (this.brochures && this.brochures.length === 1) {
			this.data.brochures.push(this.brochures[0]);
		}
	}
}
