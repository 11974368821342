import { Store, Pinia } from "pinia-class-component";
import { isNullOrEmpty, timestampFromDate, type Mutable } from '../utilities';
import { type SearchResultItem } from '../models/search-result-item';
import type { LiteClient, SearchResponse, SearchParams } from "algoliasearch/lite";
import type { SearchQuery } from "algoliasearch";

export interface FilterState {
	query: string | null;
	type: string | null;
	category: string | null;
	lecturer: string | null;
	location: string | null;
	begin: string | null;
	end: string | null;
	weekendsOnly: boolean | null;
	noPrerequisites: boolean | null;

	displayMode: DisplayMode;
}

export interface QueryArgs {
	client: LiteClient;
	page: number;
	hitsPerPage: number;
	includeFacets: boolean
}

export interface AutocompleteArgs {
	client: LiteClient;
	query: string;
}

export enum DisplayMode {
	Card = "card",
	List = "list",
	Agenda = "agenda"
}

@Store
export class CourseFilterStore extends Pinia implements FilterState {
	displayMode: DisplayMode = DisplayMode.Card;

	query: string | null = null;

	type: string | null = null;
	category: string | null = null;
	lecturer: string | null = null;
	location: string | null = null;
	begin: string | null = null;
	end: string | null = null;
	weekendsOnly: boolean = false;
	noPrerequisites: boolean = false;

	get isDateFilterActive(): boolean {
		return this.begin != null
			|| this.end != null
			|| this.weekendsOnly;
	}

	get isFilterActive(): boolean {
		return this.type != null
			|| this.category != null
			|| this.lecturer != null
			|| this.location != null
			|| this.begin != null
			|| this.end != null
			|| this.weekendsOnly
			|| this.noPrerequisites;
	}

	get filters(): string {
		var filters = [
			"NOT typeFormatted:Prüfung", // screen out exams
		];

		if (this.category != null) {
			filters.push(`categoriesFormatted:"${this.category}"`);
		}
		if (this.type != null) {
			filters.push(`typeFormatted:"${this.type}"`);
		}
		if (this.lecturer != null) {
			filters.push(`lecturers.displayName.value:"${this.lecturer}"`);
		}
		if (this.location != null) {
			filters.push(`location.displayName.value:"${this.location}"`);
		}

		if (this.noPrerequisites) {
			filters.push("hasPrerequisites:false");
		}
		if (this.weekendsOnly) {
			filters.push("isWeekend:true");
		}

		if (this.begin != null && this.end != null) {
			filters.push(`beginTimestamp: ${timestampFromDate(this.begin)} TO ${timestampFromDate(this.end)}`);
		}
		else if (this.begin != null) {
			filters.push(`beginTimestamp > ${timestampFromDate(this.begin)}`);
		}
		else if (this.end != null) {
			filters.push(`beginTimestamp < ${timestampFromDate(this.end)}`);
		}

		return filters.join(" AND ");
	}

	get urlHash(): string {
		let data = [
			this.query,
			this.type,
			this.category,
			this.begin,
			this.end,
			this.weekendsOnly,
			this.location,
			this.lecturer,
			this.noPrerequisites,
			this.displayMode,
		];
		return btoa(JSON.stringify(data));
	}

	setFromUrlHash(state: string) {
		if (state == null || state.length === 0) {
			return;
		}
		try {
			[
				this.query,
				this.type,
				this.category,
				this.begin,
				this.end,
				this.weekendsOnly,
				this.location,
				this.lecturer,
				this.noPrerequisites,
				this.displayMode,
			] = JSON.parse(atob(state));
		} catch (e) {
			console.error("Could not restore filter state from url hash.");
		}
	}

	get indexName(): string {
		if (this.begin != null || this.end != null || this.weekendsOnly || this.displayMode === DisplayMode.Agenda) {
			return import.meta.env.VITE_chronologicalIndexName!;
		}
		return import.meta.env.VITE_defaultIndexName!;
	}

	setFilter(state: Partial<FilterState>) {
		Object.assign(this, state);
	}

	resetFilter() {
		this.type = null;
		this.category = null;
		this.lecturer = null;
		this.location = null;
		this.begin = null;
		this.end = null;
		this.weekendsOnly = false;
		this.noPrerequisites = false;
	}

	setDisplayMode(mode: DisplayMode) {
		this.displayMode = mode;
	}

	async sendQuery(args: QueryArgs): Promise<SearchResponse<SearchResultItem>> {
		let query: SearchQuery = {
			indexName: this.indexName,
			query: this.query ?? "",
			page: args.page,
			hitsPerPage: args.hitsPerPage,
			filters: this.filters,
			facets: args.includeFacets ? ["*"] : [],
			facetingAfterDistinct: true,
			attributesToSnippet: ["description:20"],
			snippetEllipsisText: "…",
			clickAnalytics: true,
		};

		if (this.begin != null || this.end != null || this.weekendsOnly || this.displayMode === DisplayMode.Agenda) {
			if (query.filters!.length > 0) {
				query.filters += " AND ";
			}
			query.filters += `beginTimestamp>${Date.now() / 1000 | 0}` // last line of defense against old results
		}

		let response = await args.client.searchForHits<SearchResultItem>({
			requests: [query]
		});
		return response.results[0] as SearchResponse<SearchResultItem>;
	}

	async autocompleteQuery(args: AutocompleteArgs): Promise<SearchResponse<SearchResultItem>> {
		let response = await args.client.searchForHits<SearchResultItem>({
			requests: [{
				indexName: import.meta.env.VITE_defaultIndexName!,
				query: args.query,
				clickAnalytics: true,
				hitsPerPage: 5,
				attributesToSnippet: ["description:16"],
				snippetEllipsisText: "…",
				filters: "NOT typeFormatted:Prüfung" // screen out exams
			}]
		});
		return response.results[0] as SearchResponse<SearchResultItem>;
	}
}
