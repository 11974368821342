<div class="course-navigation">
	<v-list selectable class="course-navigation__list bg-grey-lighten-3 py-4" :selected="[activeItemName]" v-if="$vuetify.display.smAndUp">
		<v-list-item v-for="item in items" :href="item.url" :key="item.name" :value="item.name">
			<v-list-item-title>
				<span class="course-navigation__item-title">{{item.title}}</span>
			</v-list-item-title>
		</v-list-item>
	</v-list>
	<v-expansion-panels v-model="openPanel" v-else>
		<v-expansion-panel>
			<v-expansion-panel-title hide-actions>
				<v-row dense align="center">
					<v-col>
						<h2 class="mb-0">{{activeItem?.title}}</h2>
					</v-col>
					<v-col cols="auto">
						<v-btn variant="text" :prepend-icon="$icons.mdiChevronDown" color="primary" v-if="openPanel !== 0">Menu</v-btn>
						<v-btn variant="text" size="small" :icon="$icons.mdiClose" v-else/>
					</v-col>
				</v-row>
			</v-expansion-panel-title>
			<v-expansion-panel-text>
				<v-list selectable density="compact" class="course-navigation__list" :selected="[activeItemName]">
					<v-list-item v-for="item in items" :href="item.url" :key="item.name" :value="item.name">
						<v-list-item-title>
							<span class="course-navigation__item-title">{{item.title}}</span>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-expansion-panel-text>
		</v-expansion-panel>
	</v-expansion-panels>
</div>
