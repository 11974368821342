<div class="course-filter">
	<h3 class="course-filter__title">Kursfilter</h3>

	<v-autocomplete variant="solo" clearable persistent-clear label="Kursart" :items="extractFacetValues(facets.typeFormatted)" :return-object="false" v-model="type">
		<template v-slot:item="{ props, item }">
			<v-list-item v-bind="props" :title="item.title">
				<template v-slot:append v-if="item.raw.count">
					<v-chip class="bg-grey-lighten-4">{{ item.raw.count }}</v-chip>
				</template>
			</v-list-item>
		</template>
	</v-autocomplete>

	<v-autocomplete variant="solo" clearable persistent-clear dense label="Kategorie" :items="extractFacetValues(facets.categoriesFormatted)" :return-object="false" v-model="category">
		<template v-slot:item="{ props, item }">
			<v-list-item v-bind="props" :title="item.title">
				<template v-slot:append v-if="item.raw.count">
					<v-chip class="bg-grey-lighten-4">{{ item.raw.count }}</v-chip>
				</template>
			</v-list-item>
		</template>
	</v-autocomplete>

	<v-date-input hide-details clearable persistent-clear hide-actions variant="solo" label="Von" placeholder="dd.MM.yyyy" :prepend-inner-icon="$icons.mdiCalendar" :prepend-icon="null" v-model="begin" @click:clear="begin = null" />
	<v-date-input hide-details clearable persistent-clear hide-actions variant="solo" label="Bis" placeholder="dd.MM.yyyy" :prepend-inner-icon="$icons.mdiCalendar" :prepend-icon="null" v-model="end" @click:clear="end = null" />
	<v-checkbox hide-details class="mb-4" label="Nur Wochenenden" v-model="weekendsOnly" />

	<v-autocomplete clearable persistent-clear variant="solo" dense label="Standort" :items="extractFacetValues(facets['location.displayName.value'])" :return-object="false" v-model="location">
		<template v-slot:item="{ props, item }">
			<v-list-item v-bind="props" :title="item.title">
				<template v-slot:append v-if="item.raw.count">
					<v-chip class="bg-grey-lighten-4">{{ item.raw.count }}</v-chip>
				</template>
			</v-list-item>
		</template>
	</v-autocomplete>

	<v-autocomplete hide-details variant="solo" clearable persistent-clear dense label="Kursleitung" :items="extractFacetValues(facets['lecturers.displayName.value'])" :return-object="false" v-model="lecturer">
		<template v-slot:item="{ props, item }">
			<v-list-item v-bind="props" :title="item.title">
				<template v-slot:append v-if="item.raw.count">
					<v-chip class="bg-grey-lighten-4">{{ item.raw.count }}</v-chip>
				</template>
			</v-list-item>
		</template>
	</v-autocomplete>

	<v-checkbox hide-details label="Keine Vorkenntnisse erforderlich" v-model="noPrerequisites" />
</div>
