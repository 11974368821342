import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";
import { format, fromUnixTime, getMonth, getYear, getDayOfYear } from "date-fns";
import { de as deLocale } from "date-fns/locale";
import queryString from "query-string";

import { render } from "./course-result-list-item.html";
import type { SearchResultItem } from "../models/search-result-item";
import { FavoriteToggle } from '../favorites/favorite-toggle';
import { extractValueFromDisplayName, getLocationNames } from '../utilities';

@Component({
	render,
	components: {
		FavoriteToggle
	}
})
export class CourseResultListItem extends Vue {
	@Prop({ required: true })
	value: SearchResultItem;

	@Prop({ default: false })
	showDate: boolean;

	@Prop({ required: false })
	queryId: string | null;

	get imageUrl() {
		if (this.value.imageUrl == null) {
			return "";
		}
		var parsed = queryString.parseUrl(this.value.imageUrl);
		parsed.query.width = "100";
		parsed.query.height = "100";
		return queryString.stringifyUrl(parsed);
	}

	get date(): string {
		if (this.value.beginTimestamp == null || this.value.endTimestamp == null) {
			return "";
		}
		var begin = fromUnixTime(this.value.beginTimestamp);
		var end = fromUnixTime(this.value.endTimestamp);

		if (getDayOfYear(begin) === getDayOfYear(end)) {
			return format(begin, "D. MMMM yyyy", { locale: deLocale });
		}
		else if (getYear(begin) === getYear(end)) {
			if (getMonth(begin) === getMonth(end)) {
				return `${format(begin, "d.", { locale: deLocale })}–${format(end, "d. MMMM yyyy", { locale: deLocale })}`;
			}
			else {
				return `${format(begin, "d. MMM", { locale: deLocale })}–${format(end, "d. MMM yyyy", { locale: deLocale })}`;
			}
		} else {
			return `${format(begin, "d.MM.yyyy", { locale: deLocale })}–${format(end, "d.MM.yyyy", { locale: deLocale })}`;
		}
	}

	get locationName(): string {
		return extractValueFromDisplayName(this.value.location?.displayName);
	}

	get locationNames(): string {
		return getLocationNames(this.value);
	}

	open() {
		this.$emit("clicked", this.value.objectID);
		location.href = this.value.url;
	}
}
