import { Vue, Component, Prop, Watch, Inject, Provide } from "@wagich/vue-facing-decorator-metadata";
import { create, enforce, omitWhen, only, test, warn, type SuiteResult } from "vest";
import "vest/enforce/email";

import { State, Gender } from "../utilities";
import { render } from "./download-order.html";

interface DownloadOrderRequest {
	firstname: string;
	lastname: string;
	email: string;
	gender: Gender | null;
}

const validator = create((data: DownloadOrderRequest, fieldName: string | undefined = undefined) => {
	only(fieldName);

	test("gender", "Bitte wähle eine Anrede aus", () => {
		enforce(data.gender).inside([Gender.Male, Gender.Female]);
	});

	test("firstname", "Bitte gib deinen Vornamen ein", () => {
		enforce(data.firstname).isNotEmpty();
	});

	test("lastname", "Bitte gib deinen Nachnamen ein", () => {
		enforce(data.lastname).isNotEmpty();
	});

	test("email", () => {
		enforce(data.email)
			.message("Bitte gib deine E-Mail-Adresse ein")
			.isNotEmpty()
			.message("Bitte gib eine gültige E-Mail-Adresse ein")
			.isEmail();
	});
});

@Component({
	render,
})
export class DownloadOrder extends Vue {
	validations: SuiteResult<string, string> = validator.get();
	state: State = State.Initial;

	@Prop({ required: false, default: false }) isModal: boolean;
	@Prop() documentId: string;
	@Prop() assetId: string;
	@Prop() contentSelector: string | null;

	data: DownloadOrderRequest = {
		firstname: "",
		lastname: "",
		email: "",
		gender: null,
	}

	contentHtml: string = "";
	signupForNewsletter: boolean = false;

	get maleLabel(): string {
		return this.data.firstname ? `Lieber ${this.data.firstname}` : `Lieber Max`;
	}
	get femaleLabel(): string {
		return this.data.firstname ? `Liebe ${this.data.firstname}` : `Liebe Maxine`;
	}

	mounted() {
		if (this.contentSelector != null) {
			let contentSourceElement = document.querySelector(this.contentSelector);
			if (contentSourceElement != null && contentSourceElement.textContent != null) {
				this.contentHtml = contentSourceElement.innerHTML;
			}
		}
	}

	async validate(fieldName?: string) {
		await this.$nextTick();
		this.validations = validator(this.data, fieldName);
	}

	async send() {
		await this.validate();
		if (!this.validations.isValid()) {
			return;
		}

		this.state = State.Loading;

		try {
			let responseMessage = await fetch("/api/download-order", {
				body: JSON.stringify({
					...this.data,
					signupForNewsletter: this.signupForNewsletter,
					documentId: this.documentId,
					assetId: this.assetId
				}),
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			});

			if (responseMessage.status < 300) {
				this.state = State.Success;

				this.data.firstname = "";
				this.data.lastname = "";
				this.data.email = "";
				this.data.gender = null;
				this.signupForNewsletter = false;
			} else {
				this.state = State.Error;
			}

			try {
				gtag("event", "generate_lead", { event_category: "Download-Formular" });
			} catch (ex) { }
			try {
				_paq.push(["trackEvent", "Formulare", "Download angefordert"]);
			} catch { }
		} catch (ex) {
			this.state = State.Error;
		}
	}
}
