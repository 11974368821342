<v-container fluid class="next-courses home-next-courses">
	<h2>Nächste Kurse</h2>
	<template v-for="(group, key) in coursesGroupedByMonth">
		<h3 class="pt-2">{{ key }}</h3>
		<v-container fluid class="pt-0 pb-3 px-0">
			<v-row>
				<v-col xl="3" md="4" sm="6" v-for="item in group">
					<course-result-agenda-item :hide-description="true" :value="item.value" :id="$formatters.idSafe(item.value.objectID)" :data-index="item.index" />
				</v-col>
			</v-row>
		</v-container>
	</template>
	<v-row justify="center">
		<v-col cols="auto">
			<v-btn color="accent" @click="showAll">Weitere Kurse anzeigen</v-btn>
		</v-col>
	</v-row>
</v-container>
