<v-container fluid class="bg-grey-lighten-4 course-list">
	<v-row wrap align="center" justify="center" class="course-list__search-and-filter instant-search--large bg-grey-lighten-4" :class="{'elevation-3' : $vuetify.display.smAndDown}">
		<v-col style="max-width: 60rem">
			<v-text-field hide-details clearable variant="solo" placeholder="Ausbildung, Kurs oder Kursleiter suchen…" :prepend-inner-icon="$icons.mdiMagnify" :loading="state === 'loading'" v-model="query" />
		</v-col>
		<v-col cols="auto" class="pl-1" v-if="$vuetify.display.smAndDown">
			<v-btn color="accent" class="course-list__open-filter" @click="isFilterOverlayOpen = true">Filter</v-btn>
		</v-col>
		<v-col cols="12" class="pa-0">
			<v-divider />
		</v-col>
	</v-row>
	<v-row>
		<template v-if="$vuetify.display.mdAndUp">
			<v-col class="course-list__filter-sidebar-container mt-2">
				<course-filter class="course-list__filter-sidebar" :facets="facets" />
			</v-col>
			<v-divider vertical />
		</template>
		<v-col class="pl-lg-6" style="overflow: hidden; flex-grow: 99;">
			<v-row align="center" dense class="mb-3">
				<v-col cols="auto">
					<div class="course-list__displaymode-hint">Anzeigen als:</div>
				</v-col>
				<v-col>
					<v-btn-toggle divided elevation="1" density="compact" v-model="displayMode">
						<v-btn :append-icon="$icons.mdiViewGrid" value="card">Raster</v-btn>
						<v-btn :append-icon="$icons.mdiViewList" value="list">Liste</v-btn>
						<v-btn :append-icon="$icons.mdiViewAgenda" value="agenda">Agenda</v-btn>
					</v-btn-toggle>
				</v-col>
			</v-row>

			<v-container fluid class="pa-0" v-if="displayMode === 'card'">
				<v-row>
					<v-col xl="2" lg="3" sm="4" cols="6" class="pa-2" v-for="(result, index) in results" :key="result.objectID">
						<course-result-card :value="result" :showDate="isDateFilterActive" :id="$formatters.idSafe(result.objectID)" :data-index="index" @clicked="onResultClicked($event, result.index)" v-observe="observer" />
					</v-col>
				</v-row>
			</v-container>

			<v-list v-if="displayMode === 'list'">
				<template v-for="(result, index) in results">
					<course-result-list-item :value="result" :showDate="isDateFilterActive" :id="$formatters.idSafe(result.objectID)" :data-index="index" @clicked="onResultClicked($event, result.index)" v-observe="observer" />
					<v-divider v-if="index < results.length - 1" />
				</template>
			</v-list>

			<v-container fluid class="pa-0" v-if="displayMode === 'agenda'">
				<template v-for="(group, key) in resultsGroupedByMonth">
					<h2 class="pt-2">{{ key }}</h2>
					<v-container fluid class="pa-0">
						<v-row>
							<v-col xl="3" md="4" sm="6" v-for="item in group">
								<course-result-agenda-item :value="item.value" :id="$formatters.idSafe(item.value.objectID)" :data-index="item.index" @clicked="onResultClicked($event, item.index)" v-observe="observer" />
							</v-col>
						</v-row>
					</v-container>
				</template>
			</v-container>

			<v-container fluid class="my-6" class="d-flex justify-center">
				<v-progress-circular :size="70" :width="7" color="primary" indeterminate v-if="state === 'loading'" />
				<p v-if="state === 'noResults'">
					<v-empty-state title="Sorry, wir haben keine Kurse oder Ausbildungen mit diesen Kriterien gefunden."/>
				</p>
			</v-container>
		</v-col>
	</v-row>
	<v-dialog fullscreen v-model="isFilterOverlayOpen">
		<v-card>
			<v-card-title>Kursfilter</v-card-title>
			<v-card-text>
				<course-filter :facets="facets" />
			</v-card-text>
			<v-card-actions>
				<v-btn variant="flat" color="accent" @click="load();isFilterOverlayOpen = false;">Kurse Filtern</v-btn>
				<v-btn @click="resetFilter" :disabled="!isFilterActive">Zurücksetzen</v-btn>
				<v-spacer />
				<v-btn @click="isFilterOverlayOpen = false">Schliessen</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</v-container>
