<div>
	<v-menu class="favorites-tool" :min-width="275" transition="slide-y-transition" :close-on-content-click="false" v-model="isOpen">
		<template v-slot:activator="{ props: menu }">
			<v-tooltip location="bottom">
				<template v-slot:activator="{ props: tooltip }">
					<v-btn size="large" variant="text" icon v-bind="{ ...tooltip, ...menu }">
						<v-badge overlap right color="accent">
							<template v-slot:badge>
								<span>{{ count }}</span>
							</template>
							<v-icon size="x-large" :color="!isOpen ? 'primary' : 'grey lighten-1'" :icon="$icons.mdiHeart"/>
						</v-badge>
					</v-btn>
				</template>
				<span>Merkliste</span>
			</v-tooltip>
		</template>

		<v-card class="favorites-tool__card">
			<v-card-title class="py-1 pr-1">
				<v-row align="center">
					<v-col cols="auto">
						<div class="h4 favorites-tool__card-title">Merkliste</div>
					</v-col>
					<v-col></v-col>
					<v-col cols="auto">
						<v-tooltip location="bottom">
							<template v-slot:activator="{ props }">
								<v-btn class="mr-2" variant="text" size="small" :icon="$icons.mdiDeleteSweep" :disabled="count === 0" @click="clear" v-bind="props"/>
							</template>
							<span>Merkliste löschen</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-card-title>
			<v-divider />
			<v-list class="favorites-tool__list" v-if="count > 0">
				<v-list-item :href="favorite.url" :prepend-avatar="favorite.imageUrl" v-for="favorite in favorites">
					<v-list-item-title>{{ favorite.name }}</v-list-item-title>
					<v-list-item-subtitle>{{ favorite.moduleName }}</v-list-item-subtitle>

					<template v-slot:append>
						<v-tooltip location="bottom">
							<template v-slot:activator="{ props }">
								<v-btn variant="text" size="small" color="grey" :icon="$icons.mdiDelete" @click.prevent="remove(favorite)" v-bind="props"/>
							</template>
							<span>Von der Merkliste entfernen</span>
						</v-tooltip>
					</template>
				</v-list-item>
			</v-list>
			<v-card-text v-else>
				<div>Deine Merkliste ist leer.</div>
				<div class="caption">Füge Einträge hinzu, indem du das <v-icon size="small" color="primary" :icon="$icons.mdiHeartOutline"/>-Icon benutzt.</div>
			</v-card-text>
		</v-card>
	</v-menu>
</div>
