<v-card class="brochure-order-form pa-2" :flat="flat" :class="{'bg-grey-lighten-4': !flat}">
	<v-card-title v-if="!contentHtml">
		<v-row>
			<v-col>
				<h2>{{ sendLabel }}</h2>
			</v-col>
			<v-col cols="auto">
				<v-btn :icon="$icons.mdiClose" variant="text" @click="$emit('close')" v-if="isModal"></v-btn>
			</v-col>
		</v-row>
	</v-card-title>
	<v-card-text>
		<div class="clearfix" v-html="contentHtml"></div>

		<v-row>
			<v-col>
				<v-checkbox density="compact" hide-details v-for="brochure in brochures" :value="brochure" :label="brochure" @update:modelValue="validate('brochures')" v-model="data.brochures" />
				<v-input :error-messages="validations.getErrors('brochures')" v-if="validations.hasErrors('brochures')" />
			</v-col>
		</v-row>

		<v-row>
			<v-col class="pt-0">
				<v-text-field hide-details="auto" label="Vorname" placeholder="Maxine" autocomplete="given-name" v-model="data.firstname" :error-messages="validations.getErrors('firstname')" @update:modelValue="validate('firstname')" />
			</v-col>
			<v-col class="pt-0">
				<v-text-field hide-details="auto" label="Nachname" placeholder="Muster" autocomplete="family-name" v-model="data.lastname" :error-messages="validations.getErrors('lastname')" @update:modelValue="validate('lastname')" />
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-text-field hide-details="auto" label="Strasse" placeholder="Musterstrasse 12" autocomplete="address-line1" v-model="data.address" :error-messages="validations.getErrors('address')" @update:modelValue="validate('address')" />
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<v-text-field hide-details="auto" label="PLZ" placeholder="1234" autocomplete="postal-code" v-model="data.postalCode" :error-messages="validations.getErrors('address')" @update:modelValue="validate('address')" />
			</v-col>
			<v-col>
				<v-text-field hide-details="auto" label="Ort" placeholder="Musterort" autocomplete="address-level2" v-model="data.locality" :error-messages="validations.getErrors('locality')" @update:modelValue="validate('locality')" />
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-text-field hide-details="auto" label="E-Mail" placeholder="maxinemuster@example.com" autocomplete="email" v-model="data.email" :error-messages="validations.getErrors('email')" @update:modelValue="validate('email')" />
			</v-col>
		</v-row>
		<v-row dense align="center">
			<v-col cols="auto">
				<v-radio-group hide-details="auto" class="mt-0" inline v-model="data.gender" :error-messages="validations.getErrors('gender')" @update:modelValue="validate('gender')">
					<v-radio :label="femaleLabel" value="female" />
					<v-radio :label="maleLabel" value="male" />
				</v-radio-group>
			</v-col>
			<v-spacer />
			<v-col cols="auto">
				<v-checkbox hide-details label="Newsletter abonnieren" class="mt-1" v-model="data.signupForNewsletter" />
			</v-col>
		</v-row>
	</v-card-text>
	<v-card-actions>
		<v-btn variant="elevated" color="accent" @click="send" :loading="state === 'loading'">{{sendLabel}}</v-btn>
	</v-card-actions>
	<v-snackbar top center color="success" :modelValue="state === 'success'" :timeout="10000">
		<p>Vielen Dank für dein Interesse. Du findest die Broschüre(n) in den nächsten Tagen in deinem Briefkasten!</p>
	</v-snackbar>
	<v-snackbar top center color="error" :modelValue="state === 'error'" :timeout="10000">
		<p>Das Formular konnte aus technischen Gründen nicht gesendet werden.</p>
		<p>Bitte kontaktiere uns per Telefon unter 033 225 44 22 oder schreibe uns eine Mail an <a href='mailto:mail@bodyfeet.ch'>mail@bodyfeet.ch</a>.</p>
		<p>Vielen Dank für dein Verständnis.</p>
	</v-snackbar>
</v-card>
