import { Store, Pinia } from "pinia-class-component";
import { isNullOrEmpty } from '../utilities';

export interface Favorite {
	courseId: string;
	name: string;
	moduleName: string;
	imageUrl: string;
	url: string;
}

@Store({
	persist: true,
})
export class FavoriteStore extends Pinia {
	favorites: Favorite[] = [];

	get count(): number {
		return this.favorites.length;
	}

	add(favorite: Favorite) {
		if (this.favorites.find(f => f.courseId === favorite.courseId) != null) {
			return;
		}

		this.favorites.push(favorite);

		let fullCourseName = !isNullOrEmpty(favorite.moduleName) ? `${favorite.name}: ${favorite.moduleName}` : favorite.name;

		try {
			gtag("event", "add_to_wishlist", {
				items: [
					{ item_id: favorite.courseId, item_name: fullCourseName }
				],
			});
		} catch { }
		try {
			_paq.push(["trackEvent", "Favoriten", "Kurs hinzugefügt", fullCourseName]);
		} catch { }
	}

	remove(courseId: string) {
		var itemIndex = this.favorites.findIndex(f => f.courseId === courseId);
		if (itemIndex !== -1) {
			this.favorites.splice(itemIndex, 1);
		}
	}

	clear() {
		this.favorites = [];
	}
}
