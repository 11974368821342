<div class="instant-search" :class="{ 'instant-search--large': size === 'large' }">
	<v-text-field :class="textfieldClass" ref="textfield" hide-details clearable bg-color="accent" color="white" variant="solo" :placeholder="placeholder" :prepend-inner-icon="$icons.mdiMagnify" :append-inner-icon="$icons.mdiArrowRight" :loading="isBusy" v-model="query" @click:append-inner="navigateToResults(query)" @keydown="onKeyDown" />

	<v-menu ref="menu" offset-y :nudge-top="menuVerticalOffset" nudge-left="1" content-class="instant-search__menu" :close-on-click="false" :close-on-content-click="false" :open-on-click="false" :min-width="menuWidth" :max-width="menuWidth" :activator="this.textfieldElement" :modelValue="isMenuOpen" v-if="isInitialized">
		<v-list lines="two" dense>
			<v-list-item class="instant-search__search-action" key="searchAction" @click="navigateToResults(query)">
				<v-list-item-title>
					<span>Alle Resultate für "<strong>{{query}}</strong>" anzeigen.</span>
				</v-list-item-title>
			</v-list-item>
			<v-list-subheader v-if="results.length">Top {{results.length}} Resultate für "{{query}}"</v-list-subheader>
			<v-list-item v-for="(item, index) in results" :key="item.objectID" :prepend-avatar="item.imageUrl ? getImageUrl(item) : null" @click="navigateToCourse(item, index)">
				<v-list-item-title>
					<strong>{{ item.name }}</strong>
					<span> {{ item.moduleName }}</span>
				</v-list-item-title>
				<v-list-item-subtitle class="instant-search__resultitem-description">
					<span v-html="getDescriptionSnippet(item)"></span>
				</v-list-item-subtitle>
			</v-list-item>
		</v-list>
	</v-menu>
</div>
